<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->

      <div class="card-title full-width">
        <div class="row full-width">
          <div class="col-md-6 fv-row">
            <el-input
                v-model="filters.search"
                class="m-2"
                size="large"
                placeholder="Please Input"
                :suffix-icon="Search"
            ></el-input>
          </div>
          <div class="col-md-6 fv-row">
            <el-select clearable v-model="filters.status" class="m-2 select-display-block" placeholder="Select Status"
                       size="large">
              <el-option
                  v-for="item in filterOptions.statuses"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="row full-width" v-if="checked.length > 0">
        <div class="col-md-3">
          <el-select clearable v-model="action" class="m-2 select-display-block" placeholder="Select Status"
                     size="large">
            <el-option
                v-for="item in actions"
                :label="item.label"
                :value="item.key"
                :key="item.key"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3">
          <el-button v-can="'countries.update'" @click="executeAction" :disabled="!action" class="mt-2" type="primary"
                     :loading="action_loading">Apply
          </el-button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :checked-All="checkedAll"
          @current-change="onCurrentChange"
          @check-all-change="setCheckAll"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <div
              class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
                class="form-check-input"
                type="checkbox"
                :value="data.id"
                v-model="checked"
            />
          </div>
        </template>
        <template v-slot:cell-name="{ row: data }">{{ data.name }}</template>
        <template v-slot:cell-iso3="{ row: data }">{{ data.iso3 }}</template>
        <template v-slot:cell-phone_code="{ row: data }">{{ data.phone_code }}</template>
        <template v-slot:cell-is_active="{ row: data }">
                    <span class="badge fs-7 fw-bolder" :class="`badge-light-${data.status.class}`">{{
                        data.status.label
                      }}</span>
        </template>

      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import axios from "axios";

export default defineComponent({
  name: "countries-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const tableHeader = ref([
      {key: "checkbox", sortable: false,},
      {name: "Name", key: "name", sortable: true,},
      {name: "Iso3", key: "iso3", sortable: true,},
      {name: "Phone Code", key: "phone_code", sortable: false,},
      {name: "Is Active", key: "is_active", sortable: false,},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      page: 1,
      per_page: 10
    });
    const actions = ref([]);
    const action = ref(null);
    const action_loading = ref(false);
    const checked = ref([]);
    const checkedAll = ref(false);
    const filterOptions = ref({statuses: []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Countries", []);
    });

    const fetchFilerOptions = async () => {
      try {
        let response = await ApiAxiosService.get(APIs.COUNTRY.filter_options);
        filterOptions.value.statuses = response.data.data.statuses;
        actions.value = response.data.data.actions;
      } catch (e) {
        handleError(e)
      }
    }
    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.COUNTRY.list, filters.value);
        let data = response.data.data;
        tableData.value = data.countries;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
        checked.value = [];
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {

      filters.value.per_page = value;
    }

    const sort = (data) => {
    }


    const setCheckAll = (value) => {
      checkedAll.value = value;
      if (value) checked.value = tableData.value.map(function (item) {
        return item['id']
      });
      else checked.value = [];
    }
    const executeAction = async () => {

      try {
        action_loading.value = true;
        let response = await ApiAxiosService.post(APIs.COUNTRY.actions, {action_key: action.value, ids: checked.value});
        handleSuccess(response);
        action_loading.value = false;
        fetchData();
      } catch (e) {
        action_loading.value = false;
        handleError(e)
      }
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });
    watch(checked, (currentValue, oldValue) => {

      checkedAll.value = currentValue.length == tableData.value.length;
    });
    fetchData();
    fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      actions,
      action,
      action_loading,
      executeAction,
      sort,
      fetchFilerOptions,
      filterOptions,
      checked,
      checkedAll,
      setCheckAll,
      Search, Edit, Delete, View

    };
  },

});
</script>
